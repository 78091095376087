import * as React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/common/Layout";
import { constructActiveDoc } from "../utilities/helpers";
import AnchorPrismic from "../components/elements/AnchorPrismic";

const AboutPage = ({ data }) => {
  if (!data) return null;
  const aboutData = data.page.data;
  return (
    <Layout
      settings={data.settings}
      activeDocMeta={constructActiveDoc(data.page)}
    >
      <div className="aboutPage bg-dark">
        <Container fluid>
          <Container className="my-4">
            <h3>{aboutData.title_line_a}</h3>
            <h3 className="text-primary">{aboutData.title_line_b}</h3>
          </Container>
          <GatsbyImage
            image={aboutData.about_image.gatsbyImageData}
            alt={aboutData.about_image.alt}
            className="aboutMainImage"
          />
        </Container>
        <Container className="about-main-content p-5">
          <Row>
            <Col lg={2} as="h4" className="text-primary mb-4">
              {aboutData.side_title}
            </Col>
            <Col lg={10}>
              <RichText
                render={aboutData.about_main_text.richText}
                serializeHyperlink={AnchorPrismic}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query prismicAboutpage($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicAboutpage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        title_line_a
        title_line_b
        side_title
        closure_title
        about_main_text {
          richText
        }
        about_image {
          alt
          gatsbyImageData
        }
      }
    }
  }
`;

export default AboutPage;
